<!--
 * @Author: 月魂
 * @Date: 2021-04-30 15:45:58
 * @LastEditTime: 2021-04-30 16:03:24
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\404.vue
-->
<template>
  <div class="box">
    <van-icon name="close" size="60px" />
    <div class="title">404</div>
    <div class="des">对不起，您访问的页面不存在</div>
    <van-button type="info" round class="btn" @click="jumpToHome">返回首页</van-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Button } from 'vant'
Vue.use(Icon).use(Button)

export default {
  name: '404',
  methods: {
    jumpToHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding-top: 30%;
  height: 100%;
  text-align: center;
  .title {
    font-size: 24px;
    line-height: 1.8;
    color: rgba(0, 0, 0, 0.8);
  }
  .des {
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 1.6;
  }
  .btn {
    margin: 24px 0 0;
  }
}
</style>
